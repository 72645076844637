<template>
  <span v-if="!loading">

    <h1 class="headline white--text text-center mb-3" v-if="item.id">Agentti: {{ item.name }}</h1>
    <h1 v-else class="headline white--text">Luo uusi agentti</h1>

    <v-tabs
        hide-slider
        show-arrows
        v-if="item.id"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
          v-for="tab of pageTabs"
          :key="tab.id"
          :to="'/agent/'+item.id+'/'+tab.id"
          :disabled="tabDisabler(tab)"

      >
        {{tab.name}}
        <span v-if="item[tab.count]">({{ item[tab.count] }})</span>
      </v-tab>
    </v-tabs>

      <!-- Main form. Component: chat-aliases-table -->
      <div v-if="page == 'main' || page == 'new'" class="sub-page">

        <h1 class="headline blue--text text-center mt-4 mb-3">Perustiedot</h1>

        <!-- agent edit form -->
        <v-form
            ref="form"
        >

          <span v-for="data in formFields" :key='data.item_id'>

            <!-- show chips when needed -->
            <v-chip-group
                v-if="data.chips"
                column
            >
              <v-chip
                  color="blue"
                  label
                  class="darken-4"
                  v-for="chip in item[data.item_id]"
                  :key="'chip'+chip.id"
                  :close="data.chips_close"
                  @click:close="chipsClose(data, chip)"
              >
                {{ data.chips_name ? chip[data.chips_name] : chip.name }}
              </v-chip>
            </v-chip-group>

            <component
                :is="data.component"
                v-model="item[data.item_id]"
                :items="searchItems[data.item_id]"
                :label="data.label"
                :hint="data.hint"
                :persistent-hint="data.persistent_hint"
                :class="data.class"
                :search-input.sync="searchInputs[data.item_id]"
                :loading="searchLoading[data.item_id]"
                :item-text="data.item_text"
                :item-value="data.item_value"
                :no-data-text="data.no_data_text"
                :multiple="data.multiple"
                :rows="data.rows"
                :chips="data.chips"
                :return-object="data.return_object"
                :inset="data.inset"
                :counter="data.counter"
                :light="data.light"
                :true-value="data.true_value"
                :false-value="data.false_value"
                :background-color="data.background_color"
                :rules="data.rules"
                :input="item[data.item_id]"
                :parse-input="data.parse_input"
                @pick="item[data.item_id] = $event"
                @focus="componentFocus(data)"

            >

              <!-- disable selection, using external selection system -->
              <template v-slot:selection></template>

              <template v-slot:item="data">
                {{ data.item.name }}
              </template>
              <template v-if="data.v_html">
                {{ data.v_html }}
              </template>

            </component>

          </span>

          <v-row>
            <v-col
              cols="2"
            >
              <v-btn class="button green mt-4" @click="submit">
                <template v-if="item.id">Päivitä tiedot</template>
                <template v-else>Lisää uusi agentti</template>
              </v-btn>
            </v-col>
            <v-col
                cols="4"
            >
            </v-col>
          </v-row>

        </v-form>

      </div>

      <!-- Chat aliases. Component: chat-aliases-table -->
      <span v-if="page == 'chat_aliases'">

        <h1 class="headline blue--text text-center mt-4 mb-3">Chat-aliakset</h1>
        <v-divider></v-divider>

        <aliases-table
            api_route="chat_aliases"
            :agent_id="item.id"
            aliases_type="chat_aliases"
            sort="created_at"
            :show_select="true"
            :show_expand="true"
            :api_attributes="{'agent_id': item.id}"
            :headers_prop="[
              {
                text: 'Nimi',
                align: 'start',
                sortable: true,
                value: 'name',
              },
              { text: 'Kuva', value: 'alias_media', sortable: true },
              { text: 'Viest.', value: 'chat_message_count', sortable: true },
              { text: 'Tyyppi', value: 'type', sortable: true },
              { text: 'Online', value: 'online', sortable: true },
              { text: 'Luotu', value: 'created_at', sortable: true },
            ]"
        >
        </aliases-table>

      </span>

      <!-- Audio aliases. Component: audio-aliases-table -->
      <span v-if="page == 'audio_aliases'">

        <h1 class="headline blue--text text-center mt-4 mb-3">Audio-aliakset</h1>
        <v-divider></v-divider>

        <aliases-table
            sort="id"
            :agent_id="item.id"
            aliases_type="audio_aliases"
            :api_attributes="{'agent_id': item.id}"
            api_route="audio_aliases"
            :headers_prop="[
              {
                text: 'Nimi',
                align: 'start',
                sortable: true,
                value: 'alias_name',
              },
              { value: 'select'},
            ]"
        >
        </aliases-table>

      </span>

      <!-- Contacts (työnumerot) -->
      <span v-if="page == 'contacts'">

        <h1 class="headline blue--text text-center mt-4 mb-3">Työnumerot</h1>
        <v-divider></v-divider>

        <v-row>
          <v-col>
            <v-btn
                class="v-btn ma-3 green float-right"
                @click="edit_item = {contact_type: 2};dialog=true"
                small
            >
              <v-icon>mdi-plus-circle-outline</v-icon> Lisää uusi numero
            </v-btn>
          </v-col>
        </v-row>

        <!-- data table: item.AgentConnectionInformation. nappi muokkaa -->
        <v-data-table
            :hide-default-footer="true"
            :headers="[
              {
                text: 'numero',
                value: 'address',
              },
              {
                text: 'tyyppi',
                value: 'contact_type',
              },
              {
                text: 'toiminnot',
                value: 'edit',
              },
            ]"
            :items="item.connections"
            >
            <template slot="item.address" slot-scope="data">
              {{ data.item.address }}
              <b v-if="data.item.default_connection" class="ml-1 green--text">[Oletus]</b>
            </template>

            <template slot="item.contact_type" slot-scope="data">
              {{ data.item.contact_type.name }}
            </template>

            <template slot="item.edit" slot-scope="data">
              <v-btn class="v-btn green" @click="edit_item = data.item; dialog=true;">muokkaa</v-btn>
              <v-btn class="v-btn red ml-2" @click="edit_item = data.item; confirmation=true;">poista</v-btn>
            </template>

        </v-data-table>

      </span>


      <!-- Reservations (kutsut) -->
      <span v-if="page == 'reservations'">

        <h1 class="headline blue--text text-center mt-4 mb-3">Kutsut</h1>
        <v-divider></v-divider>

        <v-row>
          <v-col>
            <v-btn
                class="v-btn ma-3 green float-right"
                @click="edit_item = {}; reservation_dialog=true"
                small
            >
              <v-icon>mdi-plus-circle-outline</v-icon> Lisää uusi varaus-aika
            </v-btn>
          </v-col>
        </v-row>

        <div class="white--text">Lista aikatauluista kun agentille voi lähettää audio-kutsupyynnön</div>

        <v-data-table
            :hide-default-footer="true"
            :headers="[
              {
                text: 'Viikonpäivä',
                value: 'week_day',
              },
              {
                text: 'Kellon aika',
                value: 'range',
              },
              {
                text: 'toiminnot',
                value: 'edit',
              },
            ]"
            :items="item.reservations"
                >
          <template slot="item.week_day" slot-scope="data">
              {{ weekdayName(data.item.week_day) }}
          </template>

          <template slot="item.range" slot-scope="data">
            {{ data.item.from }} - {{ data.item.to }}
          </template>

          <template slot="item.edit" slot-scope="data">
            <v-btn class="v-btn green" @click="edit_item = data.item; reservation_dialog=true;">muokkaa</v-btn>
            <v-btn class="v-btn red ml-2" @click="edit_item = data.item; reservation_confirmation=true;">poista</v-btn>
          </template>

        </v-data-table>


      </span>

      <!-- dialog Reservation confirmation -->
      <v-dialog
          v-model="reservation_confirmation"
          max-width="600"
      >
        <v-alert
            color="red darken-3"
            v-if="Object.keys(edit_item).length > 0"
        >
          Poistetaanko varaus {{ weekdayName(edit_item.week_day) }} {{ edit_item.from }}-{{ edit_item.to }}?

          <v-btn class="v-btn red ml-2" @click="deleteReservation(edit_item)">poista</v-btn>
        </v-alert>
      </v-dialog>

      <!-- Reservation dialog -->
      <v-dialog
          v-model="reservation_dialog"
          width="600"
      >
        <v-card>
          <v-card-title v-if="edit_item.id">
            Muokkaa
          </v-card-title>
          <v-card-title v-else>
            Uusi varaus
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-select
                  v-model="edit_item.week_day"
                  :items="week_days"
                  item-value="id"
                  item-text="week_day"
              >
                <template v-slot:item="data">
                  {{ data.item.week_day }}
                </template>
              </v-select>
              <v-text-field v-model="edit_item.from"
                placeholder="08:00"
              ></v-text-field>
              <v-text-field v-model="edit_item.to"
                            placeholder="09:00"
              ></v-text-field>
              <v-btn @click="submitReservation" :disabled="loading">Tallenna</v-btn>
            </v-form>
          </v-card-text>
        </v-card>

      </v-dialog>

      <!-- actions -->
      <span v-if="page == 'activities'">

        <h1 class="headline blue--text text-center mt-4 mb-3">Agentin aktiivisuus</h1>
        <v-divider></v-divider>


      <v-select
              v-model="selected_type"
              :items="actionTypes"
              item-value="id"
              item-name="name"
              label="Valitse aktiviteettityyppi"
      >
          <template v-slot:item="data">
            {{ data.item.name }}
          </template>
          <template v-slot:selection="data">
            {{ data.item.name }}
          </template>
      </v-select>

        <!-- group-by="action_type.name"             :hide-default-footer="true"-->
        <v-data-table
            :search="activities_search"
            :options.sync="activitiesOptions"
            :headers="[
              {
                text: 'aktiviteetti',
                value: 'action_type.name',
              },
              {
                text: 'Tapahtuma-aika',
                value: 'timestamp',
              },
              {
                text: 'tyyppi',
                value: 'type',
              },
            ]"
            :items="activities"
            :server-items-length=10000
            :footer-props="{
            'items-per-page-options':[
                100,
                200,
                300,
                500,
                ]
            }"
        >
          <!-- slot="item.action_type.name" slot-scope="data"-->
          <template v-slot:item.action_type.name="{item}">
            {{ item.action_type.name }}
            <div v-if="item.action_type.name == 'message'" style="font-size: 0.8em">
                <template v-if="item.alias">Lähettävä alias: <router-link :to="'/chat_alias/'+item.alias.id+'/main'">{{ item.alias.name }}</router-link>. </template>
                Vastausviive: {{ Math.round(item.duration_delay / 60) }} min. Viesti id: {{ item.origin_id }}
            </div>
            <div v-if="item.action_type.name == 'teaser'" style="font-size: 0.8em">
                <template v-if="item.alias">Lähettävä alias: <router-link :to="'/chat_alias/'+item.alias.id+'/main'">{{ item.alias.name }}</router-link>. </template>

                Viesti id: {{ item.origin_id }}
            </div>
            <div v-if="item.action_type.name == 'public message'" style="font-size: 0.8em">
                <template v-if="item.alias">Lähettävä alias: <router-link :to="'/chat_alias/'+item.alias.id+'/main'">{{ item.alias.name }}</router-link>. </template>

                Viesti id: {{ item.origin_id }}
            </div>
            <div v-if="item.action_type.name == 'counsel service message'" style="font-size: 0.8em">
                <template v-if="item.alias">Lähettävä alias: <router-link :to="'/chat_alias/'+item.alias.id+'/main'">{{ item.alias.name }}</router-link>. </template>
                Viesti id: {{ item.origin_id }}
            </div>
          </template>

          <template v-slot:no-data>
            Ladataan TAI agentilla ei ole hakuehtojen mukaisia aktiviteetteja
          </template>

          <template v-slot:no-results>
            Kirjoittamallasi filtterillä ei löydy mitään
          </template>

        </v-data-table>


      </span>

      <!-- audio reporting -->
      <span v-if="page == 'audio_report'">
          <h1 class="headline blue--text text-center mt-4 mb-3">Puhelujen statistiikkaa</h1>
          <v-divider></v-divider>
          Valitse päivämäärä:
          <v-row>
              <v-col cols="2">
                <v-text-field v-model="report_start_date" placeholder="01.12.23" label="alku"/>
              </v-col>
              <v-col cols="2">
                  <v-text-field v-model="report_end_date" placeholder="31.12.23" label="loppu"></v-text-field>
              </v-col>
              <v-col cols="1">
                  <v-btn
                          class="v-btn ma-3 green float-right"
                          small
                          @click="getAudioReporting"

                  >
                      HAE
                  </v-btn>
              </v-col>
          </v-row>

          <v-card class="mb-16">
              <v-card-text>
                  <h2>Yhteensä</h2>
                  <v-simple-table
                          v-if="audio_report.length > 0"
                  >

                  <template v-slot:default>
                      <!--
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          val
                        </th>
                      </tr>
                    </thead>
                    -->
                    <tbody>
                      <tr
                        v-for="item in audioReport"
                        :key="item.name"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>

                  </v-simple-table>
              </v-card-text>

              <v-divider></v-divider>

              <!-- daily -->
              <v-card-text class="mt-10">
                  <h2>Päivittäinen</h2>

                  <v-simple-table
                      v-if="audio_report[0] && audio_report[0].daily_data.length > 0"
                  >

                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Päivä
                          </th>
                          <th class="text-left">
                            Puheluita
                          </th>
                          <th class="text-left">
                            Ulossoittoja
                          </th>
                          <th class="text-left">
                            Vastattuja
                          </th>
                          <th class="text-left">
                            Asiakkaan sulkemia
                          </th>
                          <th class="text-left">
                            Agentin sulkemia
                          </th>
                          <th class="text-left">
                            Asiakas lopetti valinnalla
                          </th>
                          <th class="text-left">
                            Agentti lopetti valinnalla
                          </th>
                          <th class="text-left">
                            Vastaamattomat puhelut
                          </th>
                          <th class="text-left">
                            Vastaamattomat ulossoitot
                          </th>
                          <th class="text-left">
                            Linjalla min.
                          </th>
                          <th class="text-left">
                            Puhutut min.
                          </th>
                          <th class="text-left">
                            Keskiarvo min. per puh.
                          </th>
                          <th class="text-left">
                            Linjalla min/puh. ka.
                          </th>
                            <!-- 14 -->
                        </tr>
                      </thead>
                      <tbody v-if="audio_report[0]">
                        <template
                            v-for="item in audio_report[0].daily_data"
                            >
                            <tr
                              :key="item.name"
                            >
                              <td style="width: 130px">
                                  <b
                                    style="cursor:pointer"
                                    @click="daily_details_visibility ? daily_details_visibility = null : daily_details_visibility = item.date">
                                    {{ item.date }}
                                  </b>
                                  <template v-if="item.agent_raw_report_data.length > 0">
                                    <v-icon v-if="daily_details_visibility == item.date" @click="daily_details_visibility = null" color="red">mdi-arrow-up</v-icon>
                                    <v-icon v-else @click="daily_details_visibility = item.date" color="green">mdi-arrow-down</v-icon>
                                  </template>
                              </td>
                              <td>{{ item.number_of_calls }}</td>
                              <td>{{ item.number_of_calls_to_agent }}</td>
                              <td>{{ item.successful_calls }}</td>
                              <td>{{ item.customer_hangup_ok_call }}</td>
                              <td>{{ item.agent_hangup_ok_call }}</td>
                              <td>{{ item.customer_leave_ok_call }}</td>
                              <td>{{ item.agent_leave_ok_call }}</td>
                              <td>{{ item.not_answered_by_agent }} ({{ item.missed_call_percentage }}'%)</td>
                              <td>{{ item.number_of_calls_not_to_agent }}</td>
                              <td>{{ item.agent_online_minutes }}</td>
                              <td>{{ item.agent_spoken_total }}</td>
                              <td>{{ item.agent_spoken_minutes_average }}</td>
                              <td>{{ item.online_to_call_ratio }}</td>
                            </tr>
                              <template v-if="item.agent_raw_report_data.length > 0 && daily_details_visibility == item.date">
                                  <tr
                                    :key="item.name+'child'"
                                    style="background-color: #607d8b"
                                  >
                                      <!--<td>Alias ID</td>-->
                                      <td>Alkaen</td>
                                      <td>Päättyen</td>
                                      <td>Numero</td>
                                      <td>Kesto</td>
                                      <td>Uniikki ID</td>
                                      <td>Väliaikainen ID</td>
                                      <td>Alkuperäinen puhelun ID</td>
                                      <td colspan="9">Purkusyy</td>
                                  </tr>
                                <tr
                                  v-for="(child, index) in item.agent_raw_report_data"
                                  :key="index+'child'"
                                  style="background-color: #66767e"
                                >
                                    <!--<td>{{ item.agent_id }}</td>-->
                                    <td>{{ child.start_time }}</td>
                                    <td>{{ child.end_time }}</td>
                                    <td>{{ child.agent_phone_mumber }}</td>
                                    <td>{{ duration(child.duration) }}</td>
                                    <td>{{ child.unique_id }}</td>
                                    <td>{{ child.temp_id }}</td>
                                    <td>{{ child.originated_call_id }}</td>
                                    <td colspan="9">{{ child.clearing_reason }}</td>
                                </tr>
                          </template>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>

              </v-card-text>

              <!-- raw data -->
              <v-card-text class="mt-10">
                  <h2>Puheluerittely</h2>

                  <v-simple-table
                      v-if="audio_report[0] && audio_report[0].agent_raw_report_data.length > 0"
                  >

                    <template v-slot:default>
                      <thead>
                        <tr>
                          <td>Alkaen</td>
                          <td>Päättyen</td>
                          <td>Numero</td>
                          <td>Kesto</td>
                          <td>Uniikki ID</td>
                          <td>Väliaikainen ID</td>
                          <td>Alkuperäinen puhelun ID</td>
                          <td colspan="9">Purkusyy</td>
                        </tr>
                      </thead>
                      <tbody>
                          <template>
                            <tr
                              v-for="(child, index) in audio_report[0].agent_raw_report_data"
                              :key="index+'raw_data'"
                            >
                              <td>{{ child.start_time }}</td>
                              <td>{{ child.end_time }}</td>
                              <td>{{ child.agent_phone_mumber }}</td>
                              <td>{{ duration(child.duration) }}</td>
                              <td>{{ child.unique_id }}</td>
                              <td>{{ child.temp_id }}</td>
                              <td>{{ child.originated_call_id }}</td>
                              <td colspan="9">{{ child.clearing_reason }}</td>
                          </tr>
                      </template>
                      </tbody>
                    </template>
                  </v-simple-table>

              </v-card-text>

          </v-card>
      </span>

      <!-- delete number confirmation -->
      <v-dialog
        v-model="confirmation"
        max-width="600"
        >
        <v-alert
          color="red darken-3"
        >
          Poistetaanko numero {{ edit_item.address }}?

          <v-btn class="v-btn red ml-2" @click="deleteContact(edit_item)">poista</v-btn>
        </v-alert>
      </v-dialog>

      <!-- dialog -->
      <v-dialog
          v-model="dialog"
          width="600"
          >

        <!-- contact form -->
        <v-card>
          <v-card-title v-if="edit_item.id">
            Muokkaa
          </v-card-title>
          <v-card-title v-else>
            Uusi numero
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                  v-model="edit_item.address"
                  label="Numero"
              ></v-text-field>
              <v-checkbox
                  v-model="edit_item.default_connection"
                  label="Oletusnumero"
              ></v-checkbox>
              <v-select
                  v-model="edit_item.contact_type"
                  :items="contactTypeOptions"
                  return-object
                  item-value="id"
                  item-text="name"
              >
                <template v-slot:item="data">
                  {{ data.item.name }}
                </template>
              </v-select>
              <v-btn @click="submitEditor" :disabled="loading">Tallenna</v-btn>
            </v-form>
          </v-card-text>
        </v-card>

      </v-dialog>

      <!-- snackbar message -->
      <v-snackbar
          v-model="snackbar"
          :timeout="snackbar_timeout"
          :color="snackbarColor"
        >
        <div class="text-center font-weight-bold">
          {{ snackbar_message }}
        </div>
      </v-snackbar>

  </span>
  <!-- loading screen -->
  <v-skeleton-loader v-else
     class="mx-auto"
     type="card, article, actions"
  >
  </v-skeleton-loader>
</template>

<script>
import GlobalVars from "../mixins/GlobalVars";
import AliasesTable from "../components/AliasesTable";
import DateAreaPicker from "../components/DateAreaPicker";

//manually import vuetify form objects for dynamic usage
import {
  VTextField,
  VTextarea,
  VChipGroup,
  VImg,
  VAutocomplete,
  VCheckbox,
  VSubheader,
  VDivider,
  VContainer,
  VSnackbar,
    VSimpleTable,
} from 'vuetify/lib'

export default {
  name: "Agent",
  mixins: [
    GlobalVars,
  ], //import default set of values
  components: {
    AliasesTable,
    VTextField,
    VChipGroup,
    VImg,
    VCheckbox,
    VTextarea,
    VAutocomplete,
    VSubheader,
    VDivider,
    VContainer,
    VSnackbar,
      VSimpleTable,
    DateAreaPicker
  },
  props: [
      'id',
      'page'
  ],
  data: function () {
    return {
      route: 'agents/', //change to read from env variable
        item: {
        contact_number: '',
        social_security_number: '',
      },
        daily_details_visibility: null,
        activitiesOptions: {},
      contactTypeOptions: [
        { id: 1, name: 'SIP'},
        { id: 2, name: 'DAHDI'},
        { id: 5, name: 'IAX'},
      ],
      pageTabs: [
        { id: 'main', name: 'perustiedot'},
        {
          id: 'chat_aliases',
          name: 'Chat-aliakset',
          count: "active_chat_alias"
        },
        {
          id: 'audio_aliases',
          name: 'Audio-aliakset',
          count: 'active_audio_alias'
        },
        { id: 'contacts', name: 'Työnumerot'},
        { id: 'reservations', name: 'Kutsut'},
        { id: 'activities', name: 'Aktiivisuus'},
        { id: 'audio_report', name: 'Audiorapsa'},
        //{ id: 'actions', name: 'Muut toiminnot'}, //deprecated, remove later also from route and from template
      ],
      formFields: [
        {
          component: 'v-divider',
        },
        {
          component: 'h2',
          class: "mb-2 mt-2",
          v_html: 'Henkilön tiedot',
        },
        {
          item_id: 'firstname',
          label: 'Etunimi *',
          hint: null,
          class: null,
          component: 'v-text-field',
          counter: 45,
          rules: [(v) => !!v && v.length <= 45 || 'Max 45 kirjainta'],
        },
        {
          item_id: 'lastname',
          label: 'Sukunimi *',
          hint: null,
          class: null,
          component: 'v-text-field',
          counter: 45,
          rules: [(v) => !!v && v.length <= 45 || 'Max 45 kirjainta'],
        },
        {
          item_id: 'email',
          label: 'Email-osoite *',
          hint: null,
          class: null,
          component: 'v-text-field',
          counter: 45,
          rules: [(v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(v) || 'Invalid e-mail.'
          }],
        },
        {
          item_id: 'contact_number',
          label: 'Puhelinnumero',
          hint: null,
          class: null,
          component: 'v-text-field',
          counter: 16,
          rules: [(v) => {if(v && v.length > 16) return 'Max 16 kirjainta'; else return true}],
        },
        {
          item_id: 'social_security_number',
          label: 'Hetu',
          hint: null,
          class: null,
          component: 'v-text-field',
          counter: 11,
          rules: [(v) => {if(v && v.length > 11) return 'Max 16 kirjainta'; else return true}],
        },
        {
          item_id: 'post_address',
          label: 'Katuosoite',
          component: 'v-text-field',
          counter: 128,
          rules: [(v) => {if(v && v.length > 128) return 'Max 126 kirjainta'; else return true}],
        },
        {
          item_id: 'post_number',
          label: 'Postinumero',
          component: 'v-text-field',
          counter: 16,
          rules: [(v) => {if(v && v.length > 16) return 'Max 16 kirjainta'; else return true}],
        },
        {
          item_id: 'post_office',
          label: 'Paikkakunta',
          component: 'v-text-field',
          counter: 45,
          rules: [(v) => {if(v && v.length > 45) return 'Max 45 kirjainta'; else return true}],
        },
        {
          item_id: 'description',
          label: 'Kuvaus',
          hint: null,
          class: null,
          background_color: "#4c4d4c",
          component: 'v-textarea',
          rows: 8
        },
        {
          item_id: 'bank_account',
          label: 'Tilinumero',
          component: 'v-text-field',
          counter: 45,
          rules: [(v) => {if(v && v.length > 45) return 'Max 45 kirjainta'; else return true}],
        },
        {
          component: 'v-divider',
        },
        {
          component: 'h2',
          class: "mb-2 mt-2",
          v_html: 'Järjestelmän tiedot',
        },
        {
          item_id: 'agent_code',
          label: 'Agentti koodi *',
          hint: 'Agenttikohtainen työntekijäkoodi, jota käytetään mm. kirjautumaan työasemalle',
          class: null,
          component: 'v-text-field',
          counter: 8,
          rules: [(v) => !!v && v.length <= 8 || 'Max 8 kirjainta'],
        },
        {
          item_id: 'password',
          label: 'Salasana agenttityöasemalle *',
          component: 'v-text-field'
        },
        {
          item_id: 'tikon_code',
          label: 'Tikon-koodi',
          hint: 'Syötä neljämerkkinen Tikon-koodi',
          class: null,
          component: 'v-text-field',
          counter: 4,
          rules: [(v) => {if(v && v.length > 4) return 'Max 4 kirjainta'; else return true}],
        },
        {
          item_id: 'active',
          label: 'Aktiivinen agentti-tili',
          hint: null,
          class: null,
          component: 'v-checkbox'
        },
        {
          item_id: 'logged_in',
          label: 'Kirjautuneena puhelintyöhön',
          component: 'v-checkbox'
        },
        {
          item_id: 'busy',
          label: 'Varattu',
          component: 'v-checkbox'
        },
        {
          item_id: 'auto_logout',
          label: 'Automaattinen uloskirjaus',
          hint: null,
          class: null,
          component: 'v-checkbox'
        },
        {
          item_id: 'out_call_active',
          label: 'Ulossoitto aktiivinen',
          hint: null,
          class: null,
          component: 'v-checkbox'
        },
        {
          item_id: 'record_conferences',
          label: 'Nauhoita puhelut',
          hint: null,
          class: null,
          component: 'v-checkbox'
        },
        {
          item_id: 'invitation_in_use',
          label: 'Asiakaskutsut toiminnassa',
          hint: null,
          class: null,
          component: 'v-checkbox'
        },
        {
          item_id: 'workstation_login',
          label: 'Kirjautuneena työasemalle',
          true_value: "Y",
          false_value: "N",
          component: 'v-checkbox'
        },

        {
          item_id: 'started',
          label: 'Aloitti työt',
          hint: 'esimerkki: 01.02.24',
          class: null,
          component: 'date-area-picker',
          parse_input: 'DD.MM.YY'
        },

        {
          item_id: 'ended',
          label: 'Lopetti työt',
          hint: 'esimerkki: 01.02.24',
          class: null,
          component: 'date-area-picker',
          parse_input: 'DD.MM.YY'
        },
        {
          component: 'v-divider',
          light: false,
        },
        {
          component: 'h2',
          class: "mt-2",
          v_html: 'Työnkuvaus',
        },
        {
          item_id: 'features',
          hint: 'Valitse työt joita kyseinen agentti tekee',
          persistent_hint: true,
          class: "mb-6",
          component: 'v-autocomplete',
          multiple: true,
          chips: true,
          chips_close: true,
          chips_close_action: true,
          chips_close_message: "Työ poistettiin agentin tiedoista",
          return_object: true,
          item_text: "name",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
          on_focus: 'features', //on focus action
          report_start_date: null,
          report_end_date: null,
        },
        {
          component: 'h2',
          v_html: 'Kielitaito',
        },
        {
          item_id: 'languages',
          hint: 'Mitä kieliä agentti puhuu',
          persistent_hint: true,
          class: null,
          component: 'v-autocomplete',
          multiple: true,
          chips: true,
          chips_close: true,
          chips_close_action: true,
          chips_close_message: "Kielitaito poistettiin agentin tiedoista",
          return_object: true,
          item_text: "name",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
          on_focus: 'languages' //on focus action
        },
        {
          component: 'v-divider',
        },


      ],
      searchItems: {
        features: null,
        languages: null,
      },
      searchInputs: {
        features: "",
        languages: "",
      },
      searchLoading: {
        free: false,
        free_online: false,
      },
      edit_item: {
        contact_type: 2
      }, //item to be edited in the dialog
      dialog: false, //dialog on or not
      confirmation: false, //confirmation dialog on or not
      reservation_dialog: false,
      reservation_confirmation: false,
      week_days: [
        {id: 0, week_day: 'sunnuntai'},
        {id: 1, week_day: 'maanantai'},
        {id: 2, week_day: 'tiistai'},
        {id: 3, week_day: 'keskiviikko'},
        {id: 4, week_day: 'torstai'},
        {id: 5, week_day: 'perjantai'},
        {id: 6, week_day: 'lauantai'},
      ],
      activities: [],
      activities_search: '',
      audio_report: [],
        report_start_date: null,
        report_end_date: null,
        actionTypes: [],
        selected_type: null,
    }
  },
  computed: {

      audioReport(){

          if(this.audio_report)
            return [
                {'name': 'puheluita yhteensä', value: this.audio_report[0].number_of_calls},
                {'name': 'onnistuneet puhelut', value: this.audio_report[0].successful_calls},
                {'name': 'Suorat puhelut agentille', value: this.audio_report[0].number_of_calls_to_agent},
                {'name': 'Epäsuorat puhelut', value: this.audio_report[0].number_of_calls_not_to_agent},
                {'name': 'Agentin sulkema puhelu', value: this.audio_report[0].agent_hangup_ok_call},
                {'name': 'Asiakkaan sulkema puhelu', value: this.audio_report[0].customer_hangup_ok_call},
                {'name': 'Asiakkaan sulkema puhelu epäonnistunut', value: this.audio_report[0].customer_hangup_nok_call},
                {'name': 'Agentin vastaamattomat', value: this.audio_report[0].not_answered_by_agent+' ('+this.audio_report[0].missed_call_percentage+'%)', 'color': 'red'},
                {'name': 'Agentin poistui puhelusta', value: this.audio_report[0].agent_leave_ok_call},
                {'name': 'Asiakas poistui puhelusta', value: this.audio_report[0].customer_leave_ok_call},
                {'name': 'Asiakas poistui puhelusta epäonnistunut', value: this.audio_report[0].customer_leave_nok_call},

                {'name': 'Agentti online', value: this.audio_report[0].agent_online_minutes},
                {'name': 'Agentin puhelut', value: this.audio_report[0].agent_spoken_total},
                {'name': 'Agentin puhelut keskiarvo', value: this.audio_report[0].agent_spoken_minutes_average},
                {'name': 'Online / puhelu suhde', value: this.audio_report[0].online_to_call_ratio},

            ]
          else
              return {}

      },

  },
  watch: {

    id(){

      this.item = {}
      if(this.id) this.apiGet(this.route+this.id)
      if(this.$refs.form) this.$refs.form.reset()

    },
    page(){

      if(this.page == 'activities'){
          this.getActionTypes()
          this.getActivities()
      }
      if(this.page == 'audio_report') this.getAudioReporting()

    },
      "activitiesOptions.page"(){
        this.getActivities()
      },

      activities_search(){
          this.getActivities()
      },
      selected_type(){
          this.getActivities()
      }
  },
  methods: {

    //basic api get to item
    apiGet(route){

      if(this.loading) return
      this.loading = true

      this.axios.get(route,
          {params: {}}
      ).then((response)=>{

        if(!response.data.started) response.data.started = null
        if(!response.data.ended) response.data.ended = null

        this.item = response.data
        this.loading = false
      }).catch(e => {
        console.log(e)
        this.item = {}
        this.axiosError(e)
      });

    },

      duration(seconds) {

          if(seconds == 0) return

          const hours = Math.floor(seconds / 1000 / 3600);
          const minutes = Math.floor((seconds / 1000 % 3600) / 60);
          const remainingSeconds =  Math.floor(seconds / 1000 % 60);

          if(hours > 0)
              return `${hours}h ${minutes} min. ${remainingSeconds} sek.`;
          else
              return `${minutes} min. ${remainingSeconds} sek.`;
      },

    //what happens when chips is closed
    chipsClose(chip, item){

      if(chip.chips_close_min && (this.item[chip.item_id].length - 1) < chip.chips_close_min) {
        this.dialog = true
        this.alert = true
        this.alert_message = this.$t('release must have at least one movie.')
        return
      }

      this.item[chip.item_id].splice(this.item[chip.item_id].findIndex(x => x.id == item.id), 1)

      this.snackbar_message = chip.chips_close_message
      this.snackbar = true
    },

    //Helper function to return
    weekdayName(id){

      if(typeof this.week_days[id] !== 'undefined')
        return this.week_days[id].week_day

    },

    tabDisabler(tab){

      if(tab.id == 'chat_aliases') {
        if (this.item && this.item.features &&
            (
                this.item.features.find(v => v.id == 1) ||
                this.item.features.find(v => v.id == 2)
            )
        ) return false
        else
          return true
      }

      if(tab.id == 'audio_aliases' || tab.id == 'contacts' || tab.id == 'reservations' || tab.id == 'audio_report' ) {
        if (this.item && this.item.features &&
            (
                this.item.features.find(v => v.id == 3) ||
                this.item.features.find(v => v.id == 4)
            )
        ) return false
        else
          return true
      }

      return false
    },

    //determine what happens when certain component is focused
    componentFocus(data){

      if(data.on_focus == 'features') this.getSearchItems('features', 'features', this.searchInputs.features) //load features automaticallu when onfocus
      if(data.on_focus == 'languages') this.getSearchItems('languages', 'languages', this.searchInputs.languages) //load languages automatically when onfocus
    },

    /*
    Api functions
    */

    //number editor
    submitEditor(){

      var method = 'post'

      if(this.edit_item.id) method = 'put'

      this.axios[method]('agents/'+this.item.id+'/connections/'+(this.edit_item.id ? this.edit_item.id : ''), this.edit_item).then((response)=>{

        this.loading = false
        this.dialog = false

        if(method == 'post'){
          this.item.connections.push(response.data)
          this.snackbar = true
          this.snackbar_message = "Lisätty uusi numero"
        }else{
          this.snackbar = true
          this.snackbar_message = "Numero päivitetty"
        }

      }).catch(() => {

        this.loading = false
      });

    },

    //reservation editor
    submitReservation(){

      var method = 'post'

      if(this.edit_item.id) method = 'put'

      this.axios[method]('agents/'+this.item.id+'/reservations/'+(this.edit_item.id ? this.edit_item.id : ''), this.edit_item).then((response)=>{
        this.loading = false
        this.reservation_dialog = false

        if(method == 'post'){
          this.item.reservations.push(response.data)
          this.snackbar = true
          this.snackbar_message = "Lisätty uusi varaus-aika"
        }else{
          this.snackbar = true
          this.snackbar_message = "Varaus-aika päivitetty"
        }

      }).catch(() => {

        this.loading = false
      });

    },

    deleteReservation(item){

      this.axios.delete('agents/'+this.item.id+'/reservations/'+item.id).then(()=>{

        this.item.reservations.splice(this.item.reservations.findIndex(x => x.id == item.id), 1);
        this.reservation_confirmation = false

        this.snackbar = true
        this.snackbar_message = "Varaus-aika poistettu"

      }).catch(() => {
        this.loading = false
      });
    },

    deleteContact(item){

      this.axios.delete('agents/'+this.item.id+'/connections/'+item.id).then(()=>{

        this.item.connections.splice(this.item.connections.findIndex(x => x.id == item.id), 1);
        this.confirmation = false

        this.snackbar = true
        this.snackbar_message = "Numero poistettu"

      }).catch(() => {

        this.loading = false
      });
    },

    //submit general form
    submit(){

      //validate form
      if(!this.$refs.form.validate()) {

        this.snackbar_message = "Virheitä lomakkeessa. Selaa ylös, korjaa virheet ja yritä tallennusta uudelleen. Kiitti"
        this.snackbar = true
        this.snackbar_type = 'error'

        return
      }

      if(this.loading) return
      this.loading = true

      var method = 'post'
      var route = 'agents/'

      if(this.item.id){
        route += this.item.id
        method = 'put'
      }

      if(!this.item.tikon_code) this.item.tikon_code = null //change tikon_code = "" => null

      this.axios[method](route, this.item).then((response)=>{

        this.loading = false

        this.snackbar_message = "Agentti "+(method == 'post' ? "luotu" : "päivitetty")
        this.snackbar_type = 'success'
        this.snackbar = true

        if(response.data) this.item = response.data

        //manually compose name for emit
        response.name = this.item.firstname+' '+this.item.lastname+' ('+this.item.agent_code+')'

        //if post, make return id and store item id to this.item

        //emit item for an update for other instances
        this.$root.$emit('agents', this.item)

      }).catch(() => {
        this.loading = false
      });


    },

    getActivities(){

      this.axios.get('/agents/'+this.id+'/actions_report', {params:
              {
                  skip: (this.activitiesOptions.page * this.activitiesOptions.itemsPerPage - this.activitiesOptions.itemsPerPage),
                  take: this.activitiesOptions.itemsPerPage,
                  agent_action_type_id: this.selected_type
              }
      }).then((response)=>{
        this.activities = response.data
      })
        this.axios.get('/agents/'+this.id+'/actions_report/count')
    },

    getActionTypes(){
        this.axios.get('/reports/agent_action_types').then((response)=>{
            this.actionTypes = response.data
        })
    },

    getAudioReporting(){
        this.axios.get('reports/audio_agent_report/', {params:
                {
                    from: this.report_start_date,
                    to: this.report_end_date,
                    agent_id: this.id,
                }
        }).then((response)=>{
            this.audio_report = response.data
        })
    }

  },
  mounted(){

      this.report_start_date = this.$day().startOf('month').format("DD.MM.YY")
      this.report_end_date = this.$day().format("DD.MM.YY")

    if(this.id) this.apiGet(this.route+this.id)

    if(this.page == 'activities'){
        this.getActionTypes()
        this.getActivities()
    }
    if(this.page == 'audio_report') this.getAudioReporting()


    //update live agent info if activity is changed in the list
    this.$root.$on('agent', function(payload){

      //console.log('$on agent', payload)

      //if item is payload id, only set active to whatever it is defined
      if(this.item.id == payload.id) this.item.active = payload.active

    }.bind(this))

  }
}
</script>