<script>
import {defineComponent} from 'vue'

export default defineComponent({
      name: "DateAreaPicker",
      props: {
        input: String,
        parseInput:String,
        label: String,
        disabled: Boolean
      },
  data() {
    return {
      date: "",
      dateForPicker: null,
      showPicker: false,
    }
  },
  watch: {
    dateForPicker(){
      this.showPicker = false
      console.log('dateForPicker')
      if(this.parseInput)
        this.$emit('pick', this.$day(this.dateForPicker).format(this.parseInput))
        else
        this.$emit('pick', this.$day(this.dateForPicker).format("DD.MM.YY"))
    },
    input(){
      if(this.input){
        if(this.parseInput) this.dateForPicker = this.$day(this.input, this.parseInput).format("YYYY-MM-DD")
        else
          this.dateForPicker = this.input
      }
    }
  },
  mounted(){
        if(this.input){
          console.log('mounted '+this.input+' '+this.parseInput)
          if(this.parseInput) this.dateForPicker = this.$day(this.input, this.parseInput).format("YYYY-MM-DD")
              else
                this.dateForPicker = this.input
        }
  },
})
</script>

<template>
  <div style="position: relative">
    <v-text-field v-model="input" :label="label" outlined @click="showPicker ? showPicker = false : showPicker = true" readonly :disabled="disabled"/>
    <v-date-picker
        v-if="showPicker"
        v-model="dateForPicker"
        min="1997-01-01"
        :max="new Date().getFullYear() + 2+'-12-31'"
        class="picker"
        no-title
        elevation="15"
        ref="datePicker"
        first-day-of-week=1
        locale="fi"
    />
  </div>
</template>

<style scoped>
.picker {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 133px;
}
</style>